<template>
  <div>
    <l-button
      type="primary"
      class="g-top_right_btn"
      @click="editRow()"
    >
      添加分类
    </l-button>
    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="name"
        title="分类名称"
      />
      <l-table-column
        data-index="sort"
        title="排序"
      />

      <l-table-column title="操作">
        <template slot-scope="scope">
          <l-button type="link" @click="editRow(scope)">编辑</l-button>
          <l-button type="link" @click="deleteRow(scope)">删除</l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改系统':'添加系统'"
      :confirm-loading="editDialog.save"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="分类名称" prop="name" required>
          <l-input
            v-model="editDialog.data.name"
            placeholder="请输入分类名称"
          />
        </l-form-model-item>
        <l-form-model-item label="分类排序" prop="sort" required>
          <l-input
            v-model="editDialog.data.sort"
            placeholder="请输入分类排序"
          />
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: {
        total: 0,
        page: 1,
        size: 8,
        list: []
      },
      editDialog: {
        data: {
          id: '',
          name: '',
          sort: ''
        },
        save: false,
        show: false
      },
      rules: {
        name: [{ required: true, message: '分类名称', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.search(1)
  },
  methods: {
    async search(page) {
      if (page && page.current) {
        page = page.current
      } else {
        page = page || this.tableData.page
      }
      try {
        const data = await this.$store.dispatch('auth/classifyList', {
          page: page,
          size: this.tableData.size
        })
        this.tableData = data
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      this.editDialog.show = true
    },
    async saveDialog() {
      this.editDialog.save = true
      try {
        await this.$refs.form.validate()
        await this.$store.dispatch('auth/updateClassify', {
          id: this.editDialog.data.id,
          name: this.editDialog.data.name,
          sort: this.editDialog.data.sort,
        })
        this.editDialog = this.$options.data().editDialog
        this.search()
      } catch (e) {
        this.editDialog.save = false
      }
    },
    deleteRow(row) {
      this.$confirm({
        title: '是否删除，删除后将无法恢复？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('auth/deleteClassify', {
            id: row.id
          })
          this.search()
        }
      })
    }
  }
}
</script>

<style>

</style>
